<template>
  <div class="searchBox">
    <div class="top">
      <div class="goBack" @click="back()"></div>
      <div class="pageTitle">搜索</div>
    </div>
    <div class="searchContent">
      <div class="container">
        <div class="contentImg">
          <img src="../../image/searchImg.png" alt />
        </div>
        <div class="searInp">
          <input v-model="value" type="text" placeholder="搜索" />
        </div>
        <div class="delContent">
          <!-- <img src="../../image/searDel.png" alt /> -->
        </div>
      </div>
      <div class="searchSize" @click="searchEvent()">搜索</div>
    </div>
    <div class="recordDel">
      <div>历史记录</div>
      <div>
        <img @click="delShowEvent()" src="../../image/recordDel.png" alt />
      </div>
    </div>
    <div class="recordList">
      <div v-for="(item, index) in list" :key="index">
        <span @click="sizeEvent(item)">{{ item }}</span>
        <img
          v-if="delShow"
          @click="delEvent(item, index)"
          src="../../image/X.png"
          alt
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      delShow: false,
      value: "",
    };
  },

  mounted() {
    if (localStorage.getItem("schoolValue")) {
      this.list = JSON.parse(localStorage.getItem("schoolValue"));
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    delShowEvent() {
      this.delShow = true;
    },
    searchEvent() {
      if (this.value != "") {
        let flag = true;
        this.list.forEach((item) => {
          if (item == this.value) {
            flag = false;
          }
        });
        if (this.list.length === 10) {
          this.list.pop();
        }
        if (flag) {
          this.list.unshift(this.value);
          localStorage.setItem("schoolValue", JSON.stringify(this.list));
        }
        console.log(this.list);
        this.$router.push({
          path: "/schoolResult",
          query: { value: this.value },
        });
      } else {
        Toast("请输入要搜索的内容");
      }
    },
    delEvent(item, index) {
      this.list.splice(index, 1);
      localStorage.setItem("schoolValue", JSON.stringify(this.list));
    },
    sizeEvent(item) {
      console.log(item);
      this.value = item;
    },
  },
};
</script>

<style scoped>
.top {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.goBack {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.pageTitle {
  font-size: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 264px;
}
.searchContent {
  display: flex;
  align-items: center;
  margin-top: 36px;
}
.searchSize {
  font-size: 32px;
  font-weight: 400;
  color: #323233;
  margin-left: 16px;
}
.container {
  width: 606px;
  height: 72px;
  background: #f7f8fa;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
  z-index: 666;
}
.contentImg img {
  width: 28px;
  height: 28px;
  margin-left: 28px;
  display: flex;
}
.searInp {
  /* margin-top: -20px; */
  height: 40px;
  display: flex;
}
.searInp input {
  width: 478px;
  height: 40px;
  outline: none;
  border: none;
  background: #f7f8fa;
  font-size: 28px;
  margin-left: 10px;
}
.delContent img {
  width: 28px;
  height: 28px;
  margin-right: 18px;
}
.recordDel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}
.recordDel div:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 32px;
}
.recordDel div:nth-child(2) img {
  width: 32px;
  height: 32px;
  margin-right: 32px;
}
.recordList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
}
.recordList div {
  font-size: 28px;
  height: 58px;
  background: #f7f8fa;
  border-radius: 8px 8px 8px 8px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 58px;
  box-sizing: border-box;
  margin-right: 32px;
  margin-top: 40px;
}
.recordList div img {
  width: 24px;
  height: 24px;
}
</style>
